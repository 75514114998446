//  Format from YYYY-MM-DD to Mmmm DD, YYYY
export const DateToString = (date: string | number | Date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
  if (date) return new Date(date).toLocaleDateString('en-US', options);
};

// Format from YYYY-MM-DD:HH:MM:SS to Mmmm DD, YYYY at HH:MM AM/PM
export const DateTimeFormatToStringDate = (isoDateString: string) => {
  const date = new Date(isoDateString);

  // Format for "Tuesday, Sept 6"
  const dayFormat = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }).format(date);

  // Format for "1:30 PM UTC"
  const timeFormat =
    new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'UTC',
      hour12: true,
    }).format(date) + ' UTC';

  return {
    datePart: dayFormat,
    timePart: timeFormat,
  };
};

// Get days from a date to today un UTC
export const DaysFromToday = (date: string) => {
  const today = new Date();
  const dateToCompare = new Date(date);
  const diffTime = Math.abs(today.getTime() - dateToCompare.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Time left between today and a date in UTC and format DD:HH:MM
export const TimeLeftFromToday = (date: Date | string) => {
  // Convertir la fecha de entrada a objeto Date si es un string
  const targetDate = date instanceof Date ? date : new Date(date);

  // Calcular la diferencia en milisegundos
  const difference = targetDate.getTime() - new Date().getTime();

  // Si la fecha ya pasó, retornar "00:00:00"
  if (difference <= 0) return '00:00:00';

  // Convertir milisegundos a días, horas, minutos y segundos
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  // Formatear los valores con dos dígitos
  const formatNumber = (num: number) => num.toString().padStart(2, '0');

  // Si quedan menos de un día, devolver en formato hh:mm:ss
  if (days === 0) {
    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
  }

  // Devolver en formato dd:hh:mm
  return `${formatNumber(days)}:${formatNumber(hours)}:${formatNumber(minutes)}`;
};
