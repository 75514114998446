import { useQueryClient } from '@tanstack/react-query';
import { formatDate } from 'date-fns';
import { EllipsisVerticalIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import {
  getBrandsControllerGetAllUsersByBrandIdQueryKey,
  useBrandsControllerGetAllUsersByBrandId,
  useBrandsControllerRemoveUserFromBrand,
} from '@/api';
import { DownloadIcon } from '@/assets/icon/download';
import { Loading } from '@/components/Loading';
import { AddTeamMemberDialogFull } from '@/components/add-team-member-dialog';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { getEntries, groupBy } from '@/lib/utils';
// import { useMemberInfoDrawerContext } from '@/routes/MemberInfoDrawerProvider';
import { downloadTableAsCsv } from '@/utils/FileTools';

const MembersCreatorPage = () => {
  const { brandId } = useParams();
  // const { setUserInfoData } = useMemberInfoDrawerContext();
  if (!brandId) return null;

  const allBrandUsersQuery = useBrandsControllerGetAllUsersByBrandId(brandId);

  const queryClient = useQueryClient();
  const removeUserFromBrandMutation = useBrandsControllerRemoveUserFromBrand({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetAllUsersByBrandIdQueryKey(brandId),
        });
      },
    },
  });

  const handleRemoveUserFromBrand = (userId: string) => {
    removeUserFromBrandMutation.mutate({ brandId, userId });
  };

  if (allBrandUsersQuery.isLoading) return <Loading />;

  const brandUsers = allBrandUsersQuery.data?.brandUsers || [];
  const spaceGroupUsers = allBrandUsersQuery.data?.spaceGroupUsers || [];
  const spaceGroupUsersGroupedByUserId = groupBy(spaceGroupUsers, 'userId');

  // const handleMentionClick = (uid: string) => {
  //   if (uid) {
  //     console.log('uid', uid);
  //     setUserInfoData({
  //       memberId: uid,
  //       spaceGroupId: '64a90c64-5431-4fc6-86b0-82fd0a7bcda2',
  //     });
  //   }
  // };

  return (
    <div className="flex w-full flex-col overflow-auto px-6 py-10 xl:px-62.5 xl:py-12">
      <div className="flex items-center justify-between">
        <h1 className="py-10 text-lg font-semibold">Members</h1>
        <div className="flex gap-2.5">
          <Button
            onClick={() => {
              const rows = getEntries(spaceGroupUsersGroupedByUserId).map(
                ([_, rows]) => {
                  return [
                    rows[0].name,
                    rows[0].email,
                    rows[0].status,
                    rows[0].role,
                    `"${rows.map((row) => row.spaceGroupName).join(', ')}"`,
                    rows[0].joinedAt,
                    rows[0].totalSpend,
                  ];
                },
              );
              downloadTableAsCsv(
                [
                  'Name',
                  'Email',
                  'Status',
                  'Role',
                  'Space Group',
                  'Joined On',
                  'Total Spend',
                ],
                rows,
                'Members summary',
              );
            }}
          >
            <DownloadIcon className="h-5 w-5 stroke-white" />
            CSV
          </Button>
          <AddTeamMemberDialogFull brandId={brandId} />
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow className="text-black dark:text-inherit">
            <TableHead>Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Space Groups</TableHead>
            <TableHead>Joined On</TableHead>
            <TableHead>Total Spend</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {brandUsers.map((row) => (
            <TableRow key={row.userId}>
              <TableCell>
                <div className="flex flex-col text-base font-medium text-black dark:text-white">
                  <div>{row.name}</div>
                  <span className="text-textParagraph">{row.email}</span>
                </div>
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>{row.spaceGroupName || ''}</TableCell>
              <TableCell>{formatDate(row.joinedAt, 'MMM d, yyyy')}</TableCell>
              <TableCell>{row.totalSpend}</TableCell>
              <TableCell className="w-10">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVerticalIcon />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="bottom" align="end">
                    <DropdownMenuItem
                      className="font-medium text-error"
                      onSelect={() => handleRemoveUserFromBrand(row.userId)}
                    >
                      Remove member
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
          {getEntries(spaceGroupUsersGroupedByUserId).map(([userId, rows]) => (
            <TableRow
              key={userId}
              // onClick={() => {
              //   handleMentionClick(userId);
              // }}
            >
              <TableCell className="flex min-w-max items-center gap-2.5">
                {rows[0].avatarUrl ? (
                  <img
                    src={rows[0].avatarUrl}
                    alt={rows[0].name}
                    className="h-10 w-10 rounded-full"
                  />
                ) : (
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-light-2 dark:bg-dark-2">
                    {rows[0].name?.slice(0, 2)?.toUpperCase() || '--'}
                  </div>
                )}
                <div className="flex flex-col text-base font-medium text-black dark:text-white">
                  <div>{rows[0].name}</div>
                  <span className="text-textParagraph">{rows[0].email}</span>
                </div>
              </TableCell>
              <TableCell>{rows[0].status}</TableCell>
              <TableCell>{rows[0].role}</TableCell>
              <TableCell>
                {rows.map((row) => row.spaceGroupName).join(', ')}
              </TableCell>
              <TableCell>
                {formatDate(rows[0].joinedAt, 'MMM d, yyyy')}
              </TableCell>
              <TableCell>{rows[0].totalSpend}</TableCell>
              <TableCell className="w-10">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVerticalIcon />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="bottom" align="end">
                    <DropdownMenuItem
                      className="font-medium text-error"
                      onSelect={() => handleRemoveUserFromBrand(userId)}
                    >
                      Remove member
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default MembersCreatorPage;
