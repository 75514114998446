import { useNavigate } from 'react-router-dom';

import {
  CourseWithLessons,
  useCoursesSpacesControllerGetCourseProgress,
} from '@/api';
import { PencilIcon } from '@/assets/icon/pencil';
import { Button } from '@/components/ui/button';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { DateToString, TimeLeftFromToday } from '@/utils/dateUtils';

const CourseCard = ({
  course,
  brandId,
  spaceGroupId,
  coursesSpaceId,
}: {
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
}) => {
  const navigate = useNavigate();
  const hasPermits = useBrandPermits();

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}/edit`,
    );
  };

  const handleCourseClick = () => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}`,
    );
  };

  const courseProgress = useCoursesSpacesControllerGetCourseProgress(course.id);
  const progress = courseProgress.data?.progress;
  const isCompleted = progress === 100;

  return (
    <div className="relative">
      <div className="h-full">
        <div className="flex h-full flex-col gap-6 rounded-2xl border border-light bg-light-2 px-2.5 pb-6 pt-2.5 dark:border-dark-light dark:bg-dark-2">
          <div className="h-[200px] w-full overflow-hidden rounded-2xl bg-brand">
            {course.image && (
              <img
                src={course.image}
                alt={course.name}
                className="h-full w-full object-cover"
                draggable={false}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2.5">
            <div className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
              {course.accessType === 'TIME_UNLOCK'
                ? `${course.unlockAfterDays} days`
                : course.price
                  ? `$${course.price}`
                  : 'Free'}
            </div>
            <h2 className="text-base font-medium">{course.name}</h2>
            <div className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
              {course.lessons.length}{' '}
              {course.lessons.length === 1 ? 'lesson' : 'lessons'}
            </div>
          </div>
          <div className="flex justify-between">
            {course.hasAccess ||
            hasPermits ||
            (course.accessType === 'BUY_NOW_UNLOCK' &&
              (course.price === 0 || !course.price)) ? (
              <Button onClick={handleCourseClick}>View Course</Button>
            ) : course.accessType === 'TIME_UNLOCK' ? (
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-medium text-red-500">
                    Unlocks in: {TimeLeftFromToday(course.unlockDate!)}
                  </span>
                  <span className="text-xs text-textParagraph dark:text-dark-textParagraph">
                    Available {DateToString(course.unlockDate!)}
                  </span>
                </div>
                <Button onClick={handleCourseClick}>Preview Course</Button>
              </div>
            ) : (
              <Button onClick={() => navigate(`/checkout/course/${course.id}`)}>
                Buy ${course.price}
              </Button>
            )}

            {hasPermits && (
              <Button
                variant="icon"
                className="flex gap-1 text-brand"
                onClick={handleEdit}
              >
                <PencilIcon className="h-3 w-3 stroke-brand" />
                Edit
              </Button>
            )}
          </div>
        </div>
      </div>
      {isCompleted && (
        <div className="absolute left-5 top-5 flex items-center gap-1 rounded-full bg-white px-2 py-1 text-sm font-medium dark:bg-dark-light">
          <div className="h-2 w-2 rounded-full bg-green-500" />
          <span className="text-xs">Completed</span>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
