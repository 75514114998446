// AffiliatePage.tsx
import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

const AffiliatePage = () => {
  const [spaceGroups, setSpaceGroups] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [creatorShare, setCreatorShare] = useState('');
  const [teachlyShare, setTeachlyShare] = useState('');
  const [platformFee, setPlatformFee] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://7up3mqhbnt.us-west-2.awsapprunner.com/space-groups/all',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setSpaceGroups(data);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setErrorMessage(
          'Error al obtener los datos. Por favor, intenta nuevamente.',
        );
      }
    };

    fetchData();
  }, [token]);

  const handleEdit = (item: any) => {
    setCurrentItem(item);
    setCreatorShare(item.creatorShare || '');
    setTeachlyShare(item.teachlyShare || '');
    setPlatformFee(item.platformFee || '');
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (!currentItem) return;

    const updateData: any = {};

    // Solo incluir los campos que han sido modificados
    if (creatorShare !== currentItem.creatorShare) {
      updateData.creatorShare = parseFloat(creatorShare);
    }
    if (teachlyShare !== currentItem.teachlyShare) {
      updateData.teachlyShare = parseFloat(teachlyShare);
    }
    if (platformFee !== currentItem.platformFee) {
      updateData.platformFee = parseFloat(platformFee);
    }

    try {
      const response = await fetch(
        `https://68nfktqbsh.us-west-2.awsapprunner.com/space-groups/${currentItem.id}/update-shares`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updateData),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message || 'Error al actualizar los datos.';
        throw new Error(errorMessage);
      }

      const updatedItem = await response.json();

      // Actualizar el estado de spaceGroups
      setSpaceGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === updatedItem.id ? { ...group, ...updatedItem } : group,
        ),
      );

      // Cerrar el modal
      setIsModalOpen(false);
      setErrorMessage('');
    } catch (error: any) {
      console.error('Error al guardar los cambios:', error);
      setErrorMessage(
        error.message ||
          'Error al guardar los cambios. Por favor, intenta nuevamente.',
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  return (
    <div className="overflow-auto px-6 py-12">
      <h1 className="mb-4 text-2xl font-bold">Split Shares Table</h1>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <div className="overflow-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden rounded-lg border">
            <Table className="min-w-full">
              <TableHeader>
                <TableRow className="bg-light-2 text-dark-2 dark:bg-dark-2 dark:text-light-2">
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Name
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Type
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Price
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Creator Share
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Teachly Share
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Platform fee
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Brand Name
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Creator Email
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Actions
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="">
                {spaceGroups.map((item) => (
                  <TableRow
                    key={item.id}
                    className="hover:bg-light-2 hover:dark:bg-dark-2"
                  >
                    <TableCell className="px-4 py-2 text-sm">
                      {item.name}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.billingFrequency}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.price}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.creatorShare}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.teachlyShare}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.platformFee}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.brandName}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      {item.creatorEmail}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-sm">
                      <button
                        onClick={() => handleEdit(item)}
                        className="rounded bg-blue-500 px-3 py-1 text-white hover:bg-blue-600"
                      >
                        Editar
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="flex flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
          <DialogHeader className="flex-row items-center justify-between space-y-0">
            <DialogTitle className="text-2xl font-semibold">Edit</DialogTitle>
            <Button
              onClick={() => setIsModalOpen(false)}
              variant="icon"
              size="icon"
              className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
            >
              <XIcon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <div className="flex w-full max-w-md flex-col gap-4 rounded-lg bg-white dark:bg-dark-1">
            {errorMessage && (
              <p className="mb-2 text-red-500">{errorMessage}</p>
            )}
            <InputField
              title="Creator Share"
              type="number"
              value={creatorShare}
              onChange={(e) => setCreatorShare(e.target.value)}
            />
            <InputField
              title="Teachly Share"
              type="number"
              value={teachlyShare}
              onChange={(e) => setTeachlyShare(e.target.value)}
            />
            <InputField
              title="Platform Fee"
              type="number"
              value={platformFee}
              onChange={(e) => setPlatformFee(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={handleCloseModal} variant={'outline'}>
                Cancel
              </Button>
              <Button onClick={handleSaveChanges}>Save changes</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AffiliatePage;
