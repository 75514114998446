import { useEffect, useState } from 'react';

import { BankIcon } from '@/assets/icon/bank';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DateToString } from '@/utils/dateUtils';

const cardsPayoutMock = [
  {
    title: 'Net revenue',
    value: '$ -',
  },
  {
    title: 'Pending balance',
    value: '$ -',
  },
  {
    title: 'Available to payout',
    value: '$ -',
  },
  {
    title: 'Withdrawn',
    value: '$ -',
  },
];

const payoutHistoryMock = [
  {
    amount: '$396.00',
    account: {
      bank: 'Bank of America',
      lastFour: '1234',
    },
    initiated: '2021-10-01',
    expected: '2021-10-05',
    status: 'Pending',
  },
  {
    amount: '$65.00',
    account: {
      bank: 'Bank rakyat',
      lastFour: '8888',
    },
    initiated: '2021-11-01',
    expected: '2021-11-05',
    status: 'Completed',
  },
  {
    amount: '$111.00',
    account: {
      bank: 'Bank in Malaysia',
      lastFour: '4444',
    },
    initiated: '2021-10-04',
    expected: '2021-10-29',
    status: 'Pending',
  },
];

const transactionHistoryMock = [
  {
    name: 'John Doe',
    spaceGroup: 'Space Group 1',
    amount: '100.00',
    photo: 'https://randomuser.me/api/portraits/men/90.jpg',
    date: '2024-10-01',
  },
  {
    name: 'Pete Joe',
    spaceGroup: 'Space Group 2',
    amount: '50.00',
    photo: 'https://randomuser.me/api/portraits/men/96.jpg',
    date: '2023-12-25',
  },
  {
    name: 'Mary Jane',
    spaceGroup: 'Space Group 2',
    amount: '40.99',
    photo: '',
    date: '2023-12-25',
  },
];

const myAccountMock = {
  bank: 'Bank of America',
  lastFour: '6565',
};

const PayoutPage = () => {
  const [payoutHistory, setPayoutHistory] = useState<typeof payoutHistoryMock>(
    [],
  );
  const [transactionHistory, setTransactionHistory] = useState<
    typeof transactionHistoryMock
  >([]);
  const [myAccount, setMyAccount] = useState<typeof myAccountMock>();

  useEffect(() => {
    // Fetch data here
    setPayoutHistory([]);
    setTransactionHistory([]);
    setMyAccount(undefined);
  }, []);

  return (
    <div className="flex w-full flex-col gap-10 overflow-auto px-4 py-10 md:px-10">
      <div className="flex w-full flex-col gap-4 xl:flex-row">
        {cardsPayoutMock.map((card, index) => (
          <div
            className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 px-4 py-6 dark:bg-dark-2"
            key={index}
          >
            <h2 className="text-lg font-bold leading-3 text-textParagraph dark:text-dark-textParagraph">
              {card.title}
            </h2>
            <div className="text-xl font-semibold leading-5 text-black dark:text-white">
              {card.value}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-8 rounded-2xl bg-light-2 px-4 py-6 dark:bg-dark-2">
        <h2 className="w-full text-base font-medium text-black dark:text-white">
          Payout Account
        </h2>
        <div className="flex gap-2.5">
          <div className="flex min-h-12 min-w-12 items-center justify-center rounded-lg bg-light-3 dark:bg-dark-3">
            <BankIcon className="max-h-6 min-h-6 min-w-6 max-w-6 stroke-light-gray dark:stroke-dark-light-gray" />
          </div>
          {myAccount ? (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-base font-medium text-black dark:text-white">
                {myAccount.bank}
              </h3>
              <p className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                ••••{myAccount.lastFour}
              </p>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-base font-medium text-black dark:text-white">
                Bank Account
              </h3>
              <p className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                Bank account connected
              </p>
            </div>
          )}
          <Button
            className="h-fit w-32 flex-none p-4"
            hidden={!myAccount}
            disabled
          >
            Withdraw
          </Button>
        </div>
      </div>
      <Tabs defaultValue="payout" className="flex h-full w-full flex-col">
        <TabsList className="flex justify-start rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
          <TabsTrigger
            value="payout"
            className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
          >
            Payout history
          </TabsTrigger>
          <TabsTrigger
            value="transaction"
            className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
          >
            Transaction history
          </TabsTrigger>
        </TabsList>
        <TabsContent value="payout" className="mt-4 overflow-auto">
          {payoutHistory.length ? (
            <Table>
              <TableHeader>
                <TableRow className="border-light bg-light-2 hover:bg-light-3 dark:border-dark-light dark:bg-dark-1 dark:hover:bg-dark-2">
                  <TableHead>Amount</TableHead>
                  <TableHead>Payout account</TableHead>
                  <TableHead>Initiated</TableHead>
                  <TableHead>Expected date</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {payoutHistory.map((row, index) => (
                  <TableRow
                    key={index}
                    className="border-0 hover:bg-light-2 dark:hover:bg-dark-2"
                  >
                    <TableCell className="px-2.5 py-5">{row.amount}</TableCell>
                    <TableCell className="px-2.5 py-5">{`${row.account.bank} ••••${row.account.lastFour}`}</TableCell>
                    <TableCell className="px-2.5 py-5">
                      {DateToString(row.initiated)}
                    </TableCell>
                    <TableCell className="px-2.5 py-5">
                      {DateToString(row.expected)}
                    </TableCell>
                    <TableCell className="flex items-center gap-2.5 px-2.5 py-5">
                      <div
                        className={`h-2.5 w-2.5 flex-none rounded-full ${row.status === 'Pending' ? 'bg-green-accent' : 'bg-yellow-accent'}`}
                      />
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
              No payout history
            </div>
          )}
        </TabsContent>
        <TabsContent value="transaction" className="mt-4 overflow-auto">
          {transactionHistory.length ? (
            <div className="flex flex-col gap-4">
              {transactionHistory.map((data, index) => (
                <div
                  className="flex w-full items-start gap-2.5 rounded-lg bg-light-2 px-2.5 py-4 dark:bg-dark-2"
                  key={index}
                >
                  {data.photo ? (
                    <img
                      src={data.photo}
                      alt="user"
                      className="h-10 w-10 rounded-lg"
                    />
                  ) : (
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-lg bg-brand text-white">
                      {data.name.split('')[0] +
                        data.name.split('')[1].toUpperCase()}
                    </div>
                  )}
                  <div className="flex w-full flex-col gap-1">
                    <div className="font-medium text-black dark:text-white">
                      {data.name + ' '}
                      <span className="text-textParagraph dark:text-dark-textParagraph">
                        subscribed to ({data.spaceGroup}) for
                      </span>
                      {' $' + data.amount}
                    </div>
                    <div className="text-xs font-medium text-textParagraph dark:text-dark-textParagraph">
                      {DateToString(data.date)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
              No transaction history
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default PayoutPage;
