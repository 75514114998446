import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import { Mention, MentionBlot } from 'quill-mention';
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';

import { chatHtmlToMarkdown } from '@/lib/parser';
import { cn } from '@/lib/utils';

import './quill-editor.css';

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
Quill.register('modules/magicUrl', MagicUrl);

interface EditorProps {
  readOnly?: boolean;
  defaultValue?: any;
  onTextChange?: ({
    markdown,
    html,
  }: {
    markdown: string;
    html: string;
  }) => void;
  onSelectionChange?: (...args: any[]) => void;
  onSendMessage: () => Promise<void>;
  className?: string;
  onMentionSearch: (search: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  }[];
}

// Editor is an uncontrolled React component
const Editor = forwardRef<Quill, EditorProps>(
  (
    {
      readOnly = false,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onSendMessage,
      className,
      onMentionSearch,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onMentionSearchRef = useRef(onMentionSearch);

    const handleSubmit = async () => {
      console.log('Ejecutando handleSubmit');
      await onSendMessage();
      console.log('handleSubmit ejecutado');
      onTextChange?.({ markdown: '', html: '' });
      defaultValueRef.current = '';
      // @ts-expect-error
      ref.current?.setSelection(0, 0);
      // @ts-expect-error
      ref.current?.setContents('');
    };

    const onSendMessageRef = useRef(handleSubmit);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onSendMessageRef.current = handleSubmit;
      onMentionSearchRef.current = onMentionSearch;
    });

    useEffect(() => {
      // @ts-expect-error
      if (ref.current) {
        // @ts-expect-error
        ref.current.enable(!readOnly);
      }
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );

      const mention = onMentionSearchRef.current
        ? {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            defaultMenuOrientation: 'bottom',
            offsetTop: 20,
            dataAttributes: [
              'id',
              'value',
              'denotationChar',
              'link',
              'target',
              'name',
              'avatarUrl',
              'cometChatId',
            ],
            source: (
              searchTerm: string,
              renderList: (
                values: {
                  id: string;
                  value: string;
                  cometChatId: string;
                  name: string;
                  avatarUrl: string;
                }[],
                searchTerm: string,
              ) => void,
              _mentionChar: string,
            ) => {
              const matches = onMentionSearchRef.current?.(searchTerm);
              if (!matches) return;
              renderList(matches, searchTerm);
            },
            renderItem: (
              item: {
                id: string;
                value: string;
                cometChatId: string;
                name: string;
                avatarUrl: string;
              },
              _searchTerm: string,
            ): Node => {
              const img = document.createElement('img');
              img.src = item.avatarUrl;
              img.alt = item.name;
              img.className = 'h-5 w-5 rounded-md';
              const span = document.createElement('span');
              span.textContent = item.name;
              const container = document.createElement('div');
              container.className = 'flex items-center gap-3';
              container.appendChild(img);
              container.appendChild(span);
              return container;
            },
          }
        : null;

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        formats: ['link', 'mention'],
        placeholder: 'Share something',
        modules: {
          toolbar: false,
          keyboard: {
            bindings: {
              enter: {
                key: 'Enter',
                handler: () => {
                  onSendMessageRef.current?.();
                },
              },
            },
          },
          clipboard: {
            matchVisual: false,
          },
          history: {
            delay: 1000,
            maxStack: 500,
            userOnly: true,
          },
          magicUrl: true,
          mention,
        },
      });

      if (ref && typeof ref !== 'function') {
        ref.current = quill;
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      console.log('Editor mounted');
      quill.on(Quill.events.TEXT_CHANGE, (..._args: any[]) => {
        console.log('Ejecutando quill.on(Quill.events.TEXT_CHANGE)');
        const html = quill.root.innerHTML;
        console.log('HTML:', html);
        const markdown = chatHtmlToMarkdown(html);
        console.log('Markdown:', markdown);
        onTextChangeRef.current?.({ markdown, html });
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args: any[]) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.on(Quill.events.EDITOR_CHANGE, () => {
        console.log('Editor focused');
      });
      // Forzamos el foco al editor
      setTimeout(() => {
        quill.focus();
      }, 0);

      return () => {
        if (ref && typeof ref !== 'function') {
          ref.current = null;
        }
        container.innerHTML = '';
      };
    }, []);

    return (
      <div
        className={cn('quill-posts-share-input', className)}
        ref={containerRef}
      ></div>
    );
  },
);

Editor.displayName = 'Editor';

export default Editor;
